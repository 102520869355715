export const eventDetails = {
    data :[
        {
            title : "PolyFintech 100 API Hackathon",
            excerpt : "NETS is delighted to support the PolyFinTech 100 API Hackathon and to discover and mentor the next generation of developers and innovators as we build Singapore’s e-payments ecosystem together.",
            content : `NETS Most Innovative Payment Award goes to Polygen from Republic Polytechnic<br /><br />NETS is delighted to support the PolyFinTech 100 API Hackathon and to discover and mentor the next generation of developers and innovators as we build Singapore’s e-payments ecosystem together.<br/><br/>
            During this hackathon, it’s been exciting to see the students’ creativity in using our APIs to come up with ideas for new e-payment solutions.<br/><br/>
            Our own partnerships with other FinTechs through the sharing of our APIs has allowed NETS to accelerate our development and speed-to-market of new e-payment services and we're looking forward to working with the students to translate their ideas into viable demo products."<br/><br/><i>Mr Vincent Low, Chief Information Officer, NETS</i><br/><br/><b>About POLYFINTECH100 API HACKATHON</b><br/>This is the inaugural run of a three-month long hackathon for Polytechnic students to have access to APIs from various financial institutions and other open APIs to develop applications that potentially address gaps / needs in financial services.<br/><br/>The event is run by PolyFinTech 100 and supported by the Monetary Authority of Singapore.`,
            start : "July 28, 2018",
            capacity : "unlimited",
            image : "https://s3-ap-southeast-1.amazonaws.com/webpuppiesdev/2019/01/IMG-20180728-WA0033.jpg",
            video : null
        },{
            title : "Developer Portal Launch",
            excerpt : "Thank you for the overwhelming support and response to our Inaugural Developer Conference and launch of the NETS Developer Portal. We were heartened to have in attendance key industry players and our valued partners when we presented this new NETS Digital initiative.",
            content : "Missed out on our live feed on 21st October? Watch the video now! And stay tuned to more upcoming events like this.",
            start : "September 21, 2017",
            capacity : "unlimited",
            image : "https://s3-ap-southeast-1.amazonaws.com/webpuppiesdev/2019/01/portallaunch.jpg",
            video : "https://uat-developer.nets.com.sg/downloads/Nets_Developers_Conference_21Sept2017.mp4"
        },

    ]
}
export const S3ENV = {
    ENV : "staging",
    FILE_EXT : ".json",

    FAQ : "faq",
    API_GUIDE : "apiguide",
    API_REF : "apireferece",
    HOME: "homepage",
    GUIDE : "portalguide",
    ENQ : "enquiry",
    APP : "application",
    PROFILE : "profile",
    ENQUIRY : "enquiry",
    FORUM : "forum"
}

export const WP = {
    URL : "wp.uat-developer.netsgw.com/wp-content/uploads", // WordPress Media URL
    S3 : "s3-ap-southeast-1.amazonaws.com/dev-portal-non-prod-static-content-files" // S3 Media URL
}
import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { getObject } from '../services/storage'
import { S3ENV } from '../components/envar'
import { Card, CardImg, Button, CardText, CardDeck, CardBody, Row, Col, CardHeader, CardFooter, Carousel, CarouselItem, CarouselIndicators, CarouselCaption, CarouselControl } from 'reactstrap'
import CardStyles from "../components/styles/card.module.css";
import { navigate, Link } from '@reach/router';
import { eventDetails } from '../data/event'
import { MdChevronRight } from 'react-icons/md';
import { isBrowser, handleGetEventList } from '../services/api'
import { isLoggedIn } from '../services/auth'
import moment from 'moment'
import ReactGA from 'react-ga'

import "../components/styles/custom.css"

const items = [
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: '',
        caption: ''
    },
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: '',
        caption: ''
    },
    {
        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
        altText: '',
        caption: ''
    }
];

export default class content extends Component {
    constructor(props) {
        super(props);

        let objContent = S3ENV.HOME + S3ENV.FILE_EXT
        let envPrefix = S3ENV.ENV
        const objUri = envPrefix + '-' + objContent;

        this.state = {
            objItems: [],
            activeIndex: 0,
            eventData: []
        };


        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        if (isBrowser()) this.renderObject(objUri)
    }

    renderObject = async (objVar) => {
        let objData = await getObject(objVar)
        let objString = objData.post_content
        if(objString){

            let objArray = objString.split("||")

            this.setState({ objItems: [...this.state.objItems, ...objArray] });
        }
    }

    async componentDidMount () {

        ReactGA.initialize(`$(process.env.GA_KEY)`);
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)

        let eventData = await handleGetEventList()

        this.setState({
            eventData: eventData
        })
    }

    // Carousel Functions
    onExiting () {
        this.animating = true;
    }

    onExited () {
        this.animating = false;
    }

    next () {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous () {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex (newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    // # Carousel Functions


    render () {
        const { activeIndex } = this.state;
        const slides = this.state.objItems.map((item, index) => {
            return (
                <CarouselItem
                    className="carousel-item-style"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={index}
                    style={{
                        minHeight: `400px`,
                        background: `transparent`
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: item }} style={{ backgroundColor: "#fafafa" }} />
                    <CarouselCaption captionText="" captionHeader="" />
                </CarouselItem>
            );
        });

        return (
            <div>
                {/* <div dangerouslySetInnerHTML={ {__html: this.state.objItems.post_content} }  style={{backgroundColor: "#fafafa"}}/> */}
                <Carousel
                    style={{
                        backgroundImage: `url('/assets/2019/02/homebg.png')`
                    }}
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
                {!this.state.objItems ? null : (
                    <div>
                        <div className="wp-block-cover has-center-content aligncenter " style={{ background: `linear-gradient(to right, #FE0201 50%, #334EA9 50%)`, fontSize: `0.6rem`, maxHeight: `100px`, minHeight: `100px` }}>

                            {/* DESKTOP */}
                            <p className="wp-block-cover-text d-none d-md-block" style={{ textAlign: `left`, marginRight: `3rem`, maxWidth: `100%` }}>
                                Make your first eNETS API<br />
                                <small style={{ textAlign: `justify`, fontSize: `0.7rem`, lineHeight: `150%` }}>
                                    Ready to start integrating your app?<br />
                                    Pick your SDK and fill in your credentials.
                                </small>
                            </p>
                            <p className="wp-block-cover-text d-none d-md-block" style={{ overflow: `hidden`, borderRadius: `30px` }}>
                                <i className="fas fa-chevron-circle-right" style={{ fontSize: `4rem`, color: `white`, backgroundColor: `#334EA9`, borderRadius: `80px`, padding: `6px` }}></i>
                            </p>

                            <p className="wp-block-cover-text d-none d-md-block" style={{ textAlign: `left`, marginLeft: `4.5rem`, maxWidth: `100%` }}>
                                <Link to={!isLoggedIn() ? '/signup' : '/docs/guide'}>
                                    {!isLoggedIn() ? 'Sign up as a Developer' : 'View our API Library'}
                                </Link>
                            </p>

                            {/* MOBILE */}
                            <p className="wp-block-cover-text d-md-none" style={{ textAlign: `left`, marginRight: `0.5rem` }}>
                                Make your first eNETS API
                            </p>
                            <p className="wp-block-cover-text d-md-none" style={{ textAlign: `left`, maxWidth: `100%` }}>
                                <Link to={!isLoggedIn() ? '/signup' : '/docs/guide'}>
                                    {!isLoggedIn() ? 'Sign up as a Developer' : 'View our API Library'}
                                </Link>
                            </p>

                        </div>


                        <div
                            style={{
                                padding: `2rem`
                            }}
                        >
                            <h2>
                                Events
                            </h2>
                            <Row>
                                <Col md={12} sm={12}>
                                    <CardDeck className="justify-content">
                                        <DeckContent data={this.state.eventData} />
                                    </CardDeck>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}

            </div>
        )
    }
}


export const DeckContent = (props) => {

    
    let propsSize = props && props.data ? props.data.length : 0
    if(propsSize ==0 ){
        return null
    }
    return props.data.map(function (data, index) {

        if (index > 3) {
            return null
        }

        return (
            <Card className="col-sm-6 col-md-4 col-lg-3" style={{ padding: `0` }} key={index}>
                <CardHeader tag="h3" style={{ height: `120px`, display: `flex`, alignItems: `center`, fontFamily: `'Avenir Next','Helvetica Neue','Segoe UI','Arial',sans-serif` }}>
                    {data.title}
                </CardHeader>

                <CardBody >
                    <CardImg top style={{ maxHeight: `300px` }} src={data.main_image} />
                    <CardText className={CardStyles.eventDate}>
                        <i className="text-left">
                            {moment(data.start_date).format('DD MMM YYYY')}
                        </i>
                        <br />
                        <font className="text-justify">
                            {data.excerpt}
                        </font>
                    </CardText>
                </CardBody>
                <CardFooter className="text-right">
                    <Button
                        color="link"
                        onClick={
                            event => {
                                event.preventDefault()
                                ReactGA.event({
                                    category: 'Event',
                                    action: data.title
                                })
                                navigate('/events/view/?k=' + data.event_id, { state: { data: eventDetails.data[index] } })
                            }
                        }>
                        View More <MdChevronRight />
                    </Button>
                </CardFooter>
            </Card>
        )
    })


}
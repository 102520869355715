import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from "react"
import Content from '../containers/content'
import Layout from '../components/layout'
import generalStyles from '../components/styles/general.module.css'


const IndexPage = () => {

  return (
    <div className={generalStyles.containerBackground}>
      <Layout>
        <Content />
      </Layout>
    </div>
  )
}

export default IndexPage
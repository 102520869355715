import  { Storage } from "aws-amplify"
import { WP } from '../components/envar'

//window.log_level = 'DEBUG'

Storage.configure({
    level: `${process.env.S3_LEVEL}`
})

export const isBrowser = () => typeof window !== "undefined"

export const getObject = async ( obj ) => {
    if (!isBrowser) return false

    const ext = obj.split('.');

    if(ext[1] === 'json')
    {
        let data = "";
        let jsonData = {
            post_content : ""
        };

        let temp = await fetch('/assets/public/'+obj);
        let tempJson = await temp.json();
        jsonData.post_content = await transformURL(tempJson[0])
        
        // /*
        // await Storage.get(obj, { download: true })
        //     .then( async objData => {
        //         data = await Utf8ArrayToStr(objData.Body)

        //         jsonData.post_content = await transformURL(JSON.parse(data)[0])

        //     })
        //     .catch(async (err) => {
        //         console.log('Error axios');
        //         console.log('S3 Fetch Failed : ', err)
        //     }); 
            // */

        return jsonData;
    } else{

        let data = "";

        await Storage.get(obj, { download: true })
            .then( async objData => {
                data = await Utf8ArrayToStr(objData.Body)
                // console.log(data)
                // jsonData.post_content = await transformURL(JSON.parse(data)[0])

            })
            .catch(async (err) => {
                console.log('Error axios');
                console.log('S3 Fetch Failed : ', err)
            }); 
            // */

        return data;
    }

}


export const listObj = async () => {
    if (!isBrowser) return false

    let objJson = false;
    await Storage.list('')
        .then( async objData => {
            objJson = await objData.Body;
        })
        .catch( err => console.log(err));

    return objJson;

}

function transformURL(data) {

    if(data){
        return data.post_content.replace(/wp\.uat-developer\.netsgw\.com\/wp-content\/uploads/g, WP.S3)

    }    
}

function Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12: case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
            default:
                break;
        }
    }

    return out;
}
